<template>
  <div class="add-question-popup shop-popup">
    <div class="content content-thank" v-if="isThank">
      <img
        @click="$parent.isOpenAddQuestion = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Спасибо за обращение</h5>
      <h6>
        Служба поддержки обработает обращение и направим ответ на e-mail
        указанный в Вашем профиле - {{ profile.email }}
      </h6>
      <button @click="$parent.isOpenAddQuestion = false">
        Понятно, закрыть
      </button>
    </div>
    <div class="content" v-else>
      <img
        @click="$parent.isOpenAddQuestion = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Вопрос о продукте «{{ product.headline_preview }}»</h5>
      <div class="group-input">
        <small>Ваш вопрос</small>
        <textarea v-model="questionText"></textarea>
      </div>
      <span class="agree">
        Отправляя вопрос вы соглашаетесь с
        <a href="/">правилами публикации отзывов </a>
      </span>
      <div class="buttons">
        <button @click="setAddQuestion">Задать вопрос</button>
        <a @click="$parent.isOpenAddQuestion = false">Отменить</a>
      </div>
    </div>
  </div>
</template>

<script>
import profileMixin from "@/mixins/profileMixin";
import api from "@/api";

export default {
  mixins: [profileMixin],
  props: {
    product: Object,
  },
  data() {
    return {
      questionText: "",
      isThank: false,
    };
  },
  methods: {
    async setAddQuestion() {
      try {
        let payload = {
          product_id: this.product.id,
          text: this.questionText,
        };
        if (!payload["text"]) return false;
        await api.shop.postAddQuestion(payload);
        this.isThank = true;
      } catch (error) {
        alert("Ошибка");
      }
    },
  },
};
</script>
