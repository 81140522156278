<template>
  <span class="product-city">
    Доставка в г.
    <span @click="isAddDelivery = true">{{ city }}</span>
  </span>
  <span class="product-delivery-item" v-if="deliveryPoint">
    До ПВЗ: {{ deliveryPoint }}
  </span>
  <span class="product-delivery-item" v-if="deliveryCourier">
    Курьер: {{ deliveryCourier }}
  </span>
  <span class="product-delivery-item" v-if="deliveryCourierExpress">
    Экспресс-курьер: {{ deliveryCourierExpress }}
  </span>
  <AddDeliveryPopupBlock
    v-if="isAddDelivery"
    :deliveriesPoints="deliveriesPoints"
    :isCreate="true"
    :isSettings="false"
    :isCheckout="false"
    @tooltipDelivery="tooltipDelivery = $event"
    @changeCity="city = $event"
  />
  <SuccessTooltipBlock
    v-if="tooltipDelivery"
    :header="'Адрес сохранен по умолчанию'"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import AddDeliveryPopupBlock from "@/components/AddDeliveryPopupBlock.vue";
import SuccessTooltipBlock from "@/components/SuccessTooltipBlock.vue";
import api from "@/api";

export default {
  mixins: [mainMixin],
  components: {
    AddDeliveryPopupBlock,
    SuccessTooltipBlock,
  },
  data() {
    return {
      city: null,
      deliveryPoint: null,
      deliveryCourier: null,
      deliveryCourierExpress: null,
      isAddDelivery: false,
      deliveriesPoints: [],
      tooltipDelivery: false,
    };
  },
  watch: {
    async city(newCity) {
      try {
        const result = await api.shop.getDeliveryFromCity(newCity);
        let deliveriesPoints = result["data"]["points"];
        let deliveriesCouriers = result["data"]["couriers"];
        let deliveriesCouriersExpress = result["data"]["couriers_express"];
        let min_day = 7;
        let min_price = 9999;
        let days_text = "";
        let days_price = "";
        for (let item in deliveriesPoints) {
          item = deliveriesPoints[item];
          if (item["from_days"] < min_day) {
            min_day = item["from_days"];
            days_text = min_day + "-" + item["to_days"] + " дня";
          }
          if (item["cost_of_delivery"] < min_price) {
            min_price = item["cost_of_delivery"];
            if (min_price === 0) days_price = "бесплатно";
            else days_price = item["cost_of_delivery"] + " руб.";
          }
        }
        if (days_text !== "") this.deliveryPoint = days_price;
        else this.deliveryPoint = "";
        min_day = 7;
        min_price = 9999;
        days_text = "";
        days_price = "";
        for (let item in deliveriesCouriers) {
          item = deliveriesCouriers[item];
          if (item["from_days"] < min_day) {
            min_day = item["from_days"];
            days_text = min_day + "-" + item["to_days"] + " дня";
          }
          if (item["cost_of_delivery"] < min_price) {
            min_price = item["cost_of_delivery"];
            if (min_price === 0) days_price = "бесплатно";
            else days_price = item["cost_of_delivery"] + " руб.";
          }
        }
        if (days_text !== "") this.deliveryCourier = days_price;
        else this.deliveryCourier = "";
        min_price = 9999;
        days_price = "";
        for (let item in deliveriesCouriersExpress) {
          item = deliveriesCouriersExpress[item];
          if (item["cost_of_delivery"] < min_price) {
            min_price = item["cost_of_delivery"];
            if (min_price === 0) days_price = "бесплатно";
            else days_price = item["cost_of_delivery"] + " руб.";
          }
        }
        if (days_price !== "") {
          this.deliveryCourierExpress = "от 2-х часов, " + days_price;
        } else this.deliveryCourierExpress = "";
      } catch (error) {
        console.log(error);
      }
      localStorage.setItem("city", newCity);
      this.$emit("changeCity", newCity);
    },
  },
  async mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.deliveriesPoints = false;
        that.isOpenPopup(false);
      }
    });
    try {
      const result = await api.shop.getDeliveryPointsMaps();
      this.deliveriesPoints = result["data"]["points"];
    } catch (error) {
      console.log(error);
    }
    setTimeout(function () {
      that.city = localStorage.getItem("city");
    }, 1000);
  },
};
</script>
