<template>
  <HeaderBlock />
  <section class="simple-section page-section">
    <div v-if="simple">
      <EditorBlock :page="simple" :isProduct="false" :sourcePage="13" />
    </div>
  </section>
  <FooterBlock />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import EditorBlock from "@/components/EditorBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import api from "@/api";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    HeaderBlock,
    EditorBlock,
    FooterBlock,
  },
  data() {
    return {
      simple: null,
    };
  },
  async created() {
    try {
      let slug = this.$route.params.path_page;
      const result = await api.shop.getShopPageFromPath(slug);
      let page = result["data"]["results"][0];
      if (page["simple_page"]) this.simple = page["simple_page"];
      else window.location.href = "/";
      document.title = page["title"];
      document.getElementsByTagName("meta")["description"].content =
        page["description"];
    } catch (error) {
      window.location.href = "/";
    }
  },
};
</script>
