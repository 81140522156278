export default function (instance) {
  return {
    getUserPaymentCard() {
      return instance.get("api/v1/userPaymentCard/");
    },
    getUserDelivery() {
      return instance.get("api/v1/userDelivery/");
    },
    postUserDelivery(payload) {
      return instance.post("api/v1/userDelivery/", payload);
    },
    putUserDelivery(delivery_id, payload) {
      return instance.put("api/v1/userDelivery/" + delivery_id + "/", payload);
    },
    getUserOrders(payload) {
      return instance.get("api/v1/userOrders/", { params: payload });
    },
    postUserDeliveryDoDefault(payload) {
      return instance.post("api/v1/deliveryOfUserDoDefault/", payload);
    },
    postUserPaymentDoDefault(payload) {
      return instance.post("api/v1/userPaymentCardDoDefault/", payload);
    },
    deleteUserDelivery(delivery_id) {
      return instance.delete("api/v1/userDelivery/" + delivery_id + "/");
    },
    deleteUserPaymentCard(card_id) {
      return instance.delete("api/v1/userPaymentCard/" + card_id + "/");
    },
    getUserBonus() {
      return instance.get("api/v1/userBonus/");
    },
    getPartnerBalance() {
      return instance.get("api/v1/partnerBalance/");
    },
    putPartnerUpdate(user_id, payload) {
      return instance.put("api/v1/partnerUpdate/" + user_id + "/", payload);
    },
    postCreatePartnerPayment(payload) {
      return instance.post("api/v1/createPartnerPayment/", payload);
    },
    getPartnerInfo() {
      return instance.get("api/v1/partnerInfo/");
    },
    getUserSubscribers() {
      return instance.get("api/v1/userSubscribers/");
    },
    putUserSubscribers(user_id, payload) {
      return instance.put("api/v1/userSubscribers/" + user_id + "/", payload);
    },
  };
}
