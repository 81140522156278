<script>
import api from "@/api";
import Const from "@/api/const";

export default {
  async created() {
    let payload = {
      code: this.$route.params.path_tracking,
    };
    try {
      let result = await api.shop.postShopAutoTracker(payload);
      result = result["data"];
      if ("mailing_mass_id" in result) {
        localStorage.setItem("mailingMassId", result["mailing_mass_id"]);
      }
      if ("mailing_trigger_id" in result) {
        localStorage.setItem("mailingTriggerId", result["mailing_trigger_id"]);
      }
      localStorage.setItem("profile_id", result["id"]);
      localStorage.setItem("auth_token", result["auth_token"]);
      localStorage.setItem("profile_firstName", result["first_name"]);
      localStorage.setItem("profile_lastName", result["last_name"]);
      localStorage.setItem("profile_middleName", result["middle_name"]);
      localStorage.setItem("profile_gender", result["gender"]);
      localStorage.setItem("profile_birthday", result["birthday"]);
      localStorage.setItem("profile_email", result["email"]);
      localStorage.setItem("profile_phone", result["phone"]);
      let avatar = result["avatar"];
      if (avatar !== "") avatar = Const.API_URL + result["avatar"];
      localStorage.setItem("profile_avatar", avatar);
      window.location.href = result["redirect_to"];
    } catch (error) {
      console.log(error);
      window.location.href = "/";
    }
  },
};
</script>
