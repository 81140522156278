<template>
  <h1>Настройка профиля</h1>
  <h2 class="settings">Личные данные</h2>
  <div class="profile-item" @click.stop="isOpenProfile = true">
    <span>{{ profile.firstName }} {{ profile.lastName }}</span>
    <span v-if="profile.email">{{ profile.phone }}; {{ profile.email }}</span>
    <span v-else>{{ profile.phone }}</span>
  </div>
  <h2 class="deliveries">Адреса доставки</h2>
  <div
    v-for="item in deliveries"
    :key="item"
    class="delivery-item"
    :class="{ default: item.is_default, 'd-none': item.is_deleted }"
  >
    <span v-if="item.type === 2">Доставка курьером</span>
    <span v-else-if="item.info_point.is_postamat">Почтомат</span>
    <span v-else>Пункт самовывоза</span>
    <span v-if="item.type === 1">
      {{ item.info_point.address }}
    </span>
    <span v-else>{{ item.address_raw }}</span>
    <div>
      <span v-if="item.is_default">Адрес по умолчанию</span>
      <img
        src="@/assets/images/shop-menu-lot.svg"
        @click.stop="item.isOpenMenu = true"
      />
      <div :class="{ active: item.isOpenMenu }">
        <img
          src="@/assets/images/shop-close.svg"
          @click.stop="item.isOpenMenu = false"
        />
        <span v-if="item.is_default" @click.stop="clickDeliveryDoDefault(item)">
          Убрать по умолчанию
        </span>
        <span v-else @click.stop="clickDeliveryDoDefault(item, true)">
          Сделать по умолчанию
        </span>
        <span @click.stop="clickEditDelivery(item)">Редактировать</span>
        <span @click.stop="deleteDelivery(item)">Удалить</span>
      </div>
    </div>
  </div>
  <a class="add-item add-item-delivery">
    <span @click.stop="clickAddDelivery">Добавить адрес доставки</span>
  </a>
  <h2 class="payments">Карты для оплаты</h2>
  <div
    v-for="item in payments"
    :key="item"
    class="payment-item"
    :class="{ default: item.is_default, 'd-none': item.is_deleted }"
  >
    <span>{{ item.bank_name }}</span>
    <span>{{ item.pan }} ({{ item.payment_name }})</span>
    <div>
      <span v-if="item.is_default">Карта по умолчанию</span>
      <img
        src="@/assets/images/shop-menu-lot.svg"
        @click.stop="item.isOpenMenu = true"
      />
      <div :class="{ active: item.isOpenMenu }">
        <img
          src="@/assets/images/shop-close.svg"
          @click.stop="item.isOpenMenu = false"
        />
        <span v-if="item.is_default" @click.stop="clickPaymentDoDefault(item)">
          Убрать по умолчанию
        </span>
        <span v-else @click.stop="clickPaymentDoDefault(item, true)">
          Сделать по умолчанию
        </span>
        <span @click.stop="deletePayment(item)">Удалить</span>
      </div>
    </div>
  </div>
  <a class="add-item add-item-payment">
    <span @click.stop="addPaymentCard">Добавить карту для оплат</span>
  </a>
  <AddDeliveryPopupBlock
    v-if="isAddDelivery"
    :deliveriesPoints="deliveriesPoints"
    :isCreate="true"
    :isSettings="true"
    :isCheckout="false"
  />
  <div class="profile-popup shop-popup" :class="{ 'd-none': !isOpenProfile }">
    <div class="content">
      <img
        @click="isOpenProfile = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Личные данные</h5>
      <div class="group-input" :class="{ error: isFirstName }">
        <small>Имя *</small>
        <input type="text" placeholder="Алексей" v-model="firstName" />
      </div>
      <div class="group-input" :class="{ error: isLastName }">
        <small>Фамилия *</small>
        <input type="text" placeholder="Владимиров" v-model="lastName" />
      </div>
      <div class="group-input">
        <small>Отчество (если есть)</small>
        <input type="text" placeholder="Владимирович" v-model="middleName" />
      </div>
      <div class="group-two phone-input">
        <div class="select-country">
          <span @click="clickOpenTelCountry">{{ telCountry }}</span>
          <div :class="{ open: isOpenTelCountry }">
            <span @click="selectTelCountry('Россия')">Россия</span>
            <span @click="selectTelCountry('Казахстан')">Казахстан</span>
            <span @click="selectTelCountry('Беларусь')">Беларусь</span>
            <span @click="selectTelCountry('Украина')">Украина</span>
            <span @click="selectTelCountry('Молдавия')">Молдавия</span>
            <span @click="selectTelCountry('Турция')">Турция</span>
            <span @click="selectTelCountry('Болгария')">Болгария</span>
          </div>
        </div>
        <div class="group-input" :class="{ error: isPhone }">
          <input
            type="tel"
            v-mask="telMask"
            :placeholder="telPlaceholder"
            v-model="phone"
            ref="phone"
            @keyup="clearTel"
          />
        </div>
      </div>
      <div v-if="isPhoneExist" class="error-phone">Телефон занят</div>
      <div class="confirm-phone" v-else-if="phone !== profile.phone">
        Требуется подтверждение.
        <span @click.stop="clickConfirmPhone">
          Подтвердить смену номера телефона
        </span>
      </div>
      <div class="group-input" :class="{ error: isEmail }">
        <small>E-mail*</small>
        <input type="email" v-model="email" />
      </div>
      <div class="group-input gender-input">
        <small>Пол *</small>
        <span :class="{ active: gender === 1 }" @click.stop="gender = 1">
          Мужчина
        </span>
        <span :class="{ active: gender === 2 }" @click.stop="gender = 2">
          Женщина
        </span>
      </div>
      <div class="group-input" :class="{ error: isBirthday }">
        <small>Дата рождения *</small>
        <Datepicker v-model="birthday" :locale="ru" />
      </div>
      <button @click.stop="saveProfile">Сохранить</button>
    </div>
  </div>
  <div v-if="isOpenConfirm" class="auth-popup shop-popup">
    <div class="content">
      <span class="light">light</span>
      <img
        @click="isOpenConfirm = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <div>
        <h5>Введите код из СМС</h5>
        <div class="group-input code" :class="{ error: isErrorCode }">
          <small>4-х значный код-пароль</small>
          <input
            type="tel"
            ref="code"
            v-model="code"
            placeholder="••••"
            maxlength="4"
            @keyup="setFocus"
            @paste="setFocus"
          />
        </div>
        <span class="send" :class="{ disabled: timer > 0 }" @click="sendRepeat">
          Отправить код повторно
          <span v-if="timer > 0"> ({{ timerText }})</span>
        </span>
        <p v-if="isErrorCode" class="error-code">
          Вы ввели не верный код . Исправьте его, или отправьте новый
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import phoneMixin from "@/mixins/phoneMixin";
import AddDeliveryPopupBlock from "@/components/AddDeliveryPopupBlock.vue";
import Datepicker from "vue3-datepicker";
import { mask } from "vue-the-mask";
import "@/assets/css/personal.css";
import api from "@/api";
import { ref } from "vue";
import { ru } from "date-fns/locale";

let birthday_value = localStorage.getItem("profile_birthday");
if (birthday_value === "" || !birthday_value) birthday_value = null;
else {
  birthday_value = birthday_value.split("-");
  birthday_value = new Date(
    Number(birthday_value[0]),
    Number(birthday_value[1]) - 1,
    Number(birthday_value[2])
  );
}
const birthday = ref(birthday_value);

export default {
  mixins: [mainMixin, phoneMixin],
  components: {
    AddDeliveryPopupBlock,
    Datepicker,
  },
  props: {
    profile: Object,
  },
  directives: {
    mask,
  },
  data() {
    return {
      ru: ru,
      birthday: birthday,
      isOpenProfile: false,
      payments: [],
      editDelivery: null,
      isAddDelivery: false,
      firstName: "",
      lastName: "",
      middleName: "",
      phone: "",
      email: "",
      gender: 0,
      isFirstName: false,
      isLastName: false,
      isPhone: false,
      isEmail: false,
      isBirthday: false,
      isOpenConfirm: false,
      code: "",
      isPhoneExist: false,
      deliveriesPoints: "",
      deliveries: [],
    };
  },
  async created() {
    this.firstName = this.profile.firstName;
    this.lastName = this.profile.lastName;
    this.email = this.profile.email;
    this.phone = this.profile.phone;
    this.middleName = localStorage.getItem("profile_middleName");
    this.gender = Number(localStorage.getItem("profile_gender"));
    try {
      const result = await api.shop.getDeliveryPointsMaps();
      this.deliveriesPoints = result["data"]["points"];
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.profile.getUserPaymentCard();
      this.payments = result["data"]["results"];
      let isDefault = false;
      let lastItem = null;
      for (let item in this.payments) {
        let payment = this.payments[item];
        if (payment.is_default) {
          isDefault = true;
          break;
        }
        let updatedAt = new Date(payment["updated_at"]).getTime();
        if (!lastItem || updatedAt > lastItem["updatedAt"]) {
          lastItem = {
            item: item,
            updatedAt: updatedAt,
          };
        }
      }
      if (!isDefault && lastItem) {
        this.payments[lastItem["item"]].is_default = true;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.profile.getUserDelivery();
      this.deliveries = result["data"]["results"];
      let isDefault = false;
      let lastItem = null;
      for (let item in this.deliveries) {
        let delivery = this.deliveries[item];
        if (delivery.is_default) {
          isDefault = true;
          break;
        }
        let updatedAt = new Date(delivery["updated_at"]).getTime();
        if (!lastItem || updatedAt > lastItem["updatedAt"]) {
          lastItem = {
            item: item,
            updatedAt: updatedAt,
          };
        }
      }
      if (!isDefault && lastItem) {
        this.deliveries[lastItem["item"]].is_default = true;
      }
    } catch (error) {
      console.log(error);
    }
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenProfile = false;
        that.isAddDelivery = false;
        that.isOpenConfirm = false;
        that.isOpenPopup(false);
      }
    });
  },
  methods: {
    clickOpenTelCountry() {
      if (this.isOpenTelCountry) this.isOpenTelCountry = false;
      else this.isOpenTelCountry = true;
    },
    clickEditDelivery(item) {
      this.isAddDelivery = true;
      this.editDelivery = item;
    },
    clickAddDelivery() {
      this.isAddDelivery = true;
      this.editDelivery = null;
    },
    async clickDeliveryDoDefault(item, isDefault = false) {
      for (let delivery in this.deliveries) {
        if (item["id"] === this.deliveries[delivery]["id"]) {
          this.deliveries[delivery].is_default = isDefault;
        } else this.deliveries[delivery].is_default = false;
      }
      let data = { id: item["id"], is_default: isDefault };
      try {
        await api.profile.postUserDeliveryDoDefault(data);
      } catch (error) {
        console.log(error);
      }
    },
    async clickPaymentDoDefault(item, isDefault = false) {
      for (let payment in this.payments) {
        if (item["id"] === this.payments[payment]["id"]) {
          this.payments[payment]["is_default"] = isDefault;
        } else this.payments[payment]["is_default"] = false;
      }
      let data = { id: item["id"], is_default: isDefault };
      try {
        await api.profile.postUserPaymentDoDefault(data);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteDelivery(item) {
      item.is_deleted = true;
      try {
        await api.profile.deleteUserDelivery(item["id"]);
      } catch (error) {
        console.log(error);
      }
    },
    async deletePayment(item) {
      item.is_deleted = true;
      try {
        await api.profile.deleteUserPaymentCard(item["id"]);
      } catch (error) {
        console.log(error);
      }
    },
    async addPaymentCard() {
      try {
        const result = await api.shop.postShopAddPaymentCard();
        window.location.href = result["data"]["url"];
      } catch (error) {
        console.log(error);
      }
    },
    async saveProfile() {
      try {
        let error = false;
        let payload = {
          first_name: this.firstName.replaceAll("Ё", "Е").replaceAll("ё", "е"),
          last_name: this.lastName.replaceAll("Ё", "Е").replaceAll("ё", "е"),
          middle_name: this.middleName,
          email: this.email,
          gender: this.gender,
          birthday: this.birthday,
        };
        if (payload["first_name"] === "") {
          this.isFirstName = true;
          error = true;
        } else this.isFirstName = false;
        if (payload["last_name"] === "") {
          this.isLastName = true;
          error = true;
        } else this.isLastName = false;
        if (payload["email"] === "") {
          this.isEmail = true;
          error = true;
        } else if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
        ) {
          this.isEmail = false;
        } else {
          this.isEmail = true;
          error = true;
        }
        if (payload["birthday"] === "") {
          this.isBirthday = true;
          error = true;
        } else {
          let birthday = new Date(payload["birthday"]);
          payload["birthday"] = birthday.getFullYear();
          payload["birthday"] += "-";
          payload["birthday"] += birthday.getMonth() + 1;
          payload["birthday"] += "-";
          payload["birthday"] += birthday.getDate();
          this.isBirthday = false;
        }
        if (!error) {
          await api.auth.editProfile(payload);
          localStorage.setItem("profile_firstName", payload["first_name"]);
          localStorage.setItem("profile_lastName", payload["last_name"]);
          localStorage.setItem("profile_middleName", payload["middle_name"]);
          localStorage.setItem("profile_gender", payload["gender"]);
          localStorage.setItem("profile_birthday", payload["birthday"]);
          localStorage.setItem("profile_email", payload["email"]);
          window.location.href = location.href;
        }
      } catch (error) {
        alert("Ошибка");
      }
      return false;
    },
    async clickConfirmPhone() {
      try {
        let payload = {
          phone: this.phone.replace(/[^+\d]/g, ""),
        };
        const result = await api.auth.editPhone(payload);
        if (result["data"]["is_code"]) {
          this.isOpenConfirm = true;
          let that = this;
          setTimeout(function () {
            that.$refs["code"].focus();
          }, 100);
          this.setTimer();
        } else this.isPhoneExist = true;
      } catch (error) {
        this.isErrorCode = true;
        this.code = "";
        this.$refs.code.focus();
        let that = this;
        setTimeout(function () {
          that.isErrorCode = false;
        }, 5000);
        console.log(error);
      }
    },
    async confirmPhone() {
      try {
        let payload = {
          phone: this.phone.replace(/[^+\d]/g, ""),
          code: this.code,
        };
        const result = await api.auth.editPhone(payload);
        if (result["data"]["is_edited"]) {
          localStorage.setItem("profile_phone", this.phone);
        }
        window.location.href = location.href;
      } catch (error) {
        alert("Ошибка");
      }
    },
    sendRepeat() {
      if (this.timer === 0) {
        this.code = "";
        this.isPhone = false;
        this.timer = 91;
        this.timerText = "1:31";
        this.clickConfirmPhone();
      }
    },
  },
  watch: {
    isOpenProfile(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isAddDelivery(isOpen) {
      this.isOpenPopup(isOpen);
    },
    phone() {
      this.isPhoneExist = false;
    },
  },
};
</script>
