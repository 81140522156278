export default {
  data() {
    return {
      profile: {
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        avatar: "",
      },
    };
  },
  created() {
    this.profile = {
      id: Number(localStorage.getItem("profile_id")),
      firstName: localStorage.getItem("profile_firstName"),
      lastName: localStorage.getItem("profile_lastName"),
      email: localStorage.getItem("profile_email"),
      phone: localStorage.getItem("profile_phone"),
      avatar: localStorage.getItem("profile_avatar"),
    };
  },
  methods: {
    firstNameSymbol(profile) {
      let value = "";
      if (profile.firstName && profile.firstName !== "") {
        value += profile.firstName[0].toUpperCase();
      }
      if (profile.lastName && profile.lastName !== "") {
        value += profile.lastName[0].toUpperCase();
      }
      return value;
    },
  },
};
