<template>
  <div
    v-if="item"
    class="product-item"
    @mouseover="(event) => changeImage(event, item)"
    @mouseleave="() => firstImage(item)"
    :class="{ 'product-basket-active': item.productBasket > 0 }"
  >
    <a
      class="image"
      :style="{ background: item.firstImage.background_color }"
      :href="hrefUrl"
      v-bind:target="targetValue"
    >
      <img :src="item.firstImage.image_object_middle" />
      <span v-if="item.discount_percent" class="discount-block">
        - {{ item.discount_percent }}%
      </span>
    </a>
    <span
      class="favorite"
      v-if="item.isDisplayFavorite && locale.LOCALE === 'ru'"
      @click.stop="clickFavoriteProduct(item)"
    ></span>
    <div class="cart">
      <div class="info">
        <span v-if="item.type === 4" class="price">
          <span class="new">Цена по номиналу</span>
        </span>
        <span v-else-if="item.discount_price" class="price">
          <span class="new">
            {{ formatPrice(item.discount_price) }}
            {{ locale.TEXTS.currencyText }}
          </span>
          <span class="old">
            {{ formatPrice(item.website_price) }}
            {{ locale.TEXTS.currencyText }}
          </span>
        </span>
        <span v-else class="price">
          <span class="new">
            {{ formatPrice(item.website_price) }}
            {{ locale.TEXTS.currencyText }}
          </span>
        </span>
        <span class="name" v-if="item.type === 4">
          {{ item.headline_preview }}
        </span>
        <span class="name" v-else>
          {{ item.headline_preview }}, {{ item.feature_product_quantity }}
          <span v-if="item.release_form">
            {{
              getDeclination(item.feature_product_quantity, item.release_form)
            }}
          </span>
        </span>
      </div>
      <div v-if="isBasket && item.type !== 4" class="basket">
        <button v-if="item.productBasket > 0" @click="nextOnCart">
          <span>В корзине {{ item.productBasket }} шт.</span>
          <span class="mobile">В корзине: {{ item.productBasket }}</span>
          <span>Перейти</span>
        </button>
        <button
          v-else
          @click.stop="clickBasketProduct(item, 1, true, sourcePage, null)"
          :class="
            'basket-' +
            type +
            '-' +
            item.id +
            ' basket-' +
            item.id +
            ' ' +
            type +
            '-mobile'
          "
        >
          {{ locale.TEXTS.toBasketText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import productMixin from "@/mixins/productMixin";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, productMixin],
  props: {
    product: Object,
    searchSessionHash: String,
    isFavorite: Boolean,
    isBasket: Boolean,
    type: String,
    sourcePage: Number,
  },
  data() {
    return {
      item: null,
      locale: Locale,
      hrefUrl: "",
      targetValue: "_self",
    };
  },
  mounted() {
    this.item = this.product;
    this.hrefUrl = "/catalog/" + this.item.shop_page[0].url;
    if (this.searchSessionHash) {
      this.hrefUrl =
        this.hrefUrl + "?searchSessionHash=" + this.searchSessionHash;
      this.targetValue = "_blank";
    }
    this.item.firstImage = this.item.images[0].image;
    if (this.isFavorite) this.getFavoriteProduct(this.item);
    if (this.isBasket) this.getBasketProduct(this.item);
    if (Number(localStorage.getItem("product_favorite")) === this.item.id) {
      this.clickFavoriteProduct(this.item);
      localStorage.removeItem("product_favorite");
    }
    if (this.item.discount_price) {
      this.item.discount_percent = this.item.discount_price * 100;
      this.item.discount_percent =
        this.item.discount_percent / this.item.website_price;
      this.item.discount_percent = Math.round(100 - this.item.discount_percent);
    }
  },
};
</script>
