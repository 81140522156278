<template>
  <h1 v-if="!isProduct">{{ page.headline_h1 }}</h1>
  <div v-if="!isProduct" class="author-block">
    <span class="image">
      <img :src="page.author.avatar.image_object" />
    </span>
    <span class="text">
      {{ locale.TEXTS.authorText }}:
      <b>{{ page.author.name }}</b>
      <span>·</span>{{ dateFormat(page.author.created_at) }}
    </span>
  </div>
  <div class="blocks">
    <div
      v-for="item in blocks"
      :key="item"
      :class="'alignment-' + item.alignment"
      class="block-item"
    >
      <h2 v-if="item.type === 1" v-html="item.content"></h2>
      <h3 v-else-if="item.type === 2" v-html="item.content"></h3>
      <h4 v-else-if="item.type === 3" v-html="item.content"></h4>
      <p v-else-if="item.type === 4" v-html="item.content"></p>
      <div
        v-else-if="item.type === 5"
        v-html="item.content"
        class="quote"
      ></div>
      <div v-else-if="item.type === 6" v-html="item.content" class="list"></div>
      <div
        v-else-if="item.type === 7"
        v-html="item.content"
        class="number"
      ></div>
      <div v-else-if="item.type === 8" class="image">
        <img
          @click="openImageGallery(item, true)"
          :src="item.image.image_object_big"
        />
        <div v-if="item.isOpen" class="gallery-popup shop-popup unselectable">
          <div class="content">
            <span class="close" @click="openImageGallery(item, false)">
              <img src="@/assets/images/shop-close.svg" />
            </span>
            <swiper
              class="swiper"
              :modules="modules"
              :slides-per-view="1"
              :style="{
                '--swiper-navigation-color': '#000',
                '--swiper-navigation-size': '32px',
              }"
            >
              <swiper-slide
                v-if="checkZoom(item)"
                :class="{ zoom: item.isZoom }"
              >
                <span class="image-item">
                  <img
                    :src="item.image.image_object"
                    @click.stop="
                      item.isZoom ? (item.isZoom = false) : (item.isZoom = true)
                    "
                  />
                </span>
              </swiper-slide>
              <swiper-slide v-else class="not-zoom">
                <span class="image-item">
                  <img :src="item.image.image_object" />
                </span>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div v-else-if="item.type === 9" class="video">
        <video controls="controls">
          <source type="video/mp4" :src="item.file.file_object" />
        </video>
      </div>
      <div
        v-else-if="item.type === 10 && !item.product.is_deleted"
        class="product-block"
      >
        <a
          class="image"
          :style="{
            background: item.product.images[0].image.background_color,
          }"
          :href="'/catalog/' + item.product.shop_page[0].url"
          target="_blank"
        >
          <img :src="item.product.images[0].image.image_object_middle" />
        </a>
        <div class="info">
          <span class="name">{{ item.product.headline_preview }}</span>
          <span class="quantity" v-if="item.product.type !== 4">
            {{ item.product.feature_product_quantity }}
            <span v-if="item.product.release_form">
              {{
                getDeclination(
                  item.product.feature_product_quantity,
                  item.product.release_form
                )
              }}
            </span>
          </span>
          <span class="price" v-if="item.product.type === 4">
            <span class="new">Цена по номиналу</span>
          </span>
          <span class="price" v-else>
            <span class="new">
              {{ productPriceNew(item.product) }}
              {{ locale.TEXTS.currencyText }}
            </span>
            <span v-if="item.product.discount_price" class="old">
              {{ formatPrice(item.product.website_price) }}
              {{ locale.TEXTS.currencyText }}
            </span>
          </span>
          <span v-if="item.product.discount_price" class="benefit">
            {{ productBenefit(item.product) }}
          </span>
          <button
            v-if="item.product.productBasket > 0"
            @click="nextOnCart"
            class="product-basket-active"
          >
            <span>В корзине {{ item.product.productBasket }} шт.</span>
            <span>Перейти</span>
          </button>
          <button
            v-else-if="item.product.type !== 4"
            @click.stop="
              clickBasketProduct(item.product, 1, true, sourcePage, articleId)
            "
            :class="
              'basket-simple-' + item.product.id + ' basket-' + item.product.id
            "
          >
            {{ locale.TEXTS.toBasketText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Locale from "@/api/locale";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "@/assets/css/blog.css";

export default {
  mixins: [mainMixin, pageMixin, productMixin],
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    page: Object,
    isProduct: Boolean,
    sourcePage: Number,
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      blocks: [],
      locale: Locale,
      articleId: null,
    };
  },
  async mounted() {
    if (this.sourcePage === 5) this.articleId = this.page.id;
    this.blocks = this.page.blocks;
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        for (let item in that.blocks) {
          that.blocks[item].isOpen = false;
        }
        that.isOpenPopup(false);
      }
    });
    for (let item in this.blocks) {
      item = this.blocks[item];
      if (item.type === 10) this.getBasketProduct(item.product);
    }
  },
  methods: {
    productPriceNew(product) {
      if (product.discount_price) {
        return this.formatPrice(product.discount_price);
      }
      return this.formatPrice(product.website_price);
    },
    productBenefit(product) {
      let productAmount = product.website_price - product.discount_price;
      productAmount = this.formatPrice(productAmount);
      let productPercent = product.discount_price * 100;
      productPercent = productPercent / product.website_price;
      productPercent = Math.round(100 - productPercent);
      return (
        Locale.TEXTS.yourBenefit +
        " " +
        productPercent +
        "% (-" +
        productAmount +
        " " +
        Locale.TEXTS.currencyText +
        ".)"
      );
    },
    checkZoom(item) {
      if (item.image.width > window.innerWidth) return true;
      if (item.image.height > window.innerHeight) return true;
      return false;
    },
    openImageGallery(item, isOpen) {
      item.isOpen = isOpen;
      if (isOpen) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    },
  },
};
</script>
