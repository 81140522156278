<template>
  <div class="add-feedback-popup shop-popup">
    <div class="content">
      <img
        @click="$parent.isOpenAddFeedback = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Отзыв на продукт «{{ product.headline_preview }}»</h5>
      <h6>Оцените покупку:</h6>
      <div class="rating">
        <span
          @click="setStar(1)"
          @mouseenter="hoverStar(0)"
          @mouseleave="setStar"
          :class="{ hover: star[0] }"
        >
        </span>
        <span
          @click="setStar(2)"
          @mouseenter="hoverStar(1)"
          @mouseleave="setStar"
          :class="{ hover: star[1] }"
        >
        </span>
        <span
          @click="setStar(3)"
          @mouseenter="hoverStar(2)"
          @mouseleave="setStar"
          :class="{ hover: star[2] }"
        >
        </span>
        <span
          @click="setStar(4)"
          @mouseenter="hoverStar(3)"
          @mouseleave="setStar"
          :class="{ hover: star[3] }"
        >
        </span>
        <span
          @click="setStar(5)"
          @mouseenter="hoverStar(4)"
          @mouseleave="setStar"
          :class="{ hover: star[4] }"
        >
        </span>
      </div>
      <h6>Добавьте фотографии или видео:</h6>
      <div v-if="files.length > 0" class="files">
        <div v-for="item in files" :key="item">
          <img v-if="item.isLoading" src="@/assets/images/shop-loading.svg" />
          <img
            v-else
            :src="apiUrl + '/media/shop-review/' + item['file_name']"
          />
        </div>
        <label>
          <input type="file" ref="file" @change="handleFileUpload" />
          <span></span>
        </label>
      </div>
      <label v-else class="first-file">
        <input type="file" ref="file" @change="handleFileUpload" />
        <span>загрузите до 5 файлов (фото до 5 мб, видео до 50 мб)</span>
      </label>
      <h6>Поделитесь впечатлениями о продукте:</h6>
      <div class="group-input">
        <small>Ваш отзыв</small>
        <textarea v-model="feedbackText"></textarea>
      </div>
      <span class="agree">
        Отправляя отзыв вы соглашаетесь с
        <a href="/">правилами публикации отзывов </a>
      </span>
      <div class="buttons">
        <button v-if="isLoading" disabled="disabled">Идет загрузка...</button>
        <button v-else @click="setAddFeedback">Оставить отзыв</button>
        <a @click="$parent.isOpenAddFeedback = false">Отменить</a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import Const from "@/api/const";

export default {
  props: {
    product: Object,
  },
  data() {
    return {
      feedbackText: "",
      star: [false, false, false, false, false],
      checkStar: 0,
      files: [],
      isLoading: false,
      apiUrl: "",
    };
  },
  created() {
    this.apiUrl = Const.API_URL;
  },
  methods: {
    async setAddFeedback() {
      try {
        let payload = {
          product_id: this.product.id,
          text: this.feedbackText,
          eval: this.checkStar,
          files: [],
        };
        for (let item in this.files) {
          payload["files"].push(this.files[item]["id"]);
        }
        if (!payload["text"]) return false;
        if (payload["eval"] === 0) return false;
        await api.shop.postAddFeedback(payload);
        window.location.href = location.href;
      } catch (error) {
        alert("Ошибка");
      }
    },
    hoverStar(num) {
      for (let item = 0; item <= num; item++) {
        this.star[item] = true;
      }
    },
    setStar(num = 0) {
      if (num > 0) this.checkStar = num;
      this.star = [false, false, false, false, false];
      if (this.checkStar > 0) {
        for (let item = 0; item < this.checkStar; item++) {
          this.star[item] = true;
        }
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.files.push({ isLoading: true });
      try {
        let payload = new FormData();
        payload.append("file", this.$refs.file.files[0]);
        payload.append("folder", "shop-review");
        const result = await api.auth.uploadFile(payload);
        let files = this.files;
        this.files = [];
        for (let item in files) {
          if (!files[item].isLoading) this.files.push(files[item]);
        }
        this.files.push(result["data"]);
        this.isLoading = false;
      } catch (error) {
        alert("Ошибка");
      }
    },
  },
};
</script>
