<template>
  <div class="profile-name-popup shop-popup">
    <div class="content">
      <img
        v-if="!isCheckout"
        @click="$parent.isOpenProfileName = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Заполните профиль</h5>
      <div class="group-input">
        <small>Имя*</small>
        <input
          type="text"
          placeholder="Имя"
          ref="first-name"
          v-model="firstName"
        />
      </div>
      <div v-if="isLastName" class="group-input">
        <small>Фамилия*</small>
        <input type="text" placeholder="Фамилия" v-model="lastName" />
      </div>
      <div class="group-input" :class="{ error: isEmail }">
        <small>E-mail*</small>
        <input type="email" placeholder="E-mail" v-model="email" />
      </div>
      <button @click.stop="setProfileName">Сохранить профиль</button>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  props: {
    authToken: String,
    profile: Object,
    isCheckout: Boolean,
    isLastName: Boolean,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      isEmail: false,
    };
  },
  created() {
    let that = this;
    setTimeout(function () {
      that.$refs["first-name"].focus();
    }, 100);
  },
  mounted() {
    this.firstName = this.profile.firstName;
    this.lastName = this.profile.lastName;
    this.email = this.profile.email;
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27 && !that.isCheckout) {
        that.$parent.isOpenProfileName = false;
      }
    });
  },
  methods: {
    async setProfileName() {
      try {
        let error = false;
        let payload = {
          first_name: this.firstName.replaceAll("Ё", "Е").replaceAll("ё", "е"),
          last_name: this.lastName.replaceAll("Ё", "Е").replaceAll("ё", "е"),
          email: this.email,
        };
        if (payload["first_name"] === "") return false;
        if (payload["email"] === "") {
          this.isEmail = true;
          error = true;
        } else if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(payload["email"])
        ) {
          this.isEmail = false;
        } else {
          this.isEmail = true;
          error = true;
        }
        if (this.isLastName && payload["last_name"] === "") return false;
        if (!error) {
          await api.auth.editProfile(payload);
          localStorage.setItem("profile_firstName", payload["first_name"]);
          localStorage.setItem("profile_lastName", payload["last_name"]);
          localStorage.setItem("profile_email", payload["email"]);
          window.location.href = location.href;
        }
      } catch (error) {
        console.log(error);
        alert("Ошибка");
      }
      return false;
    },
  },
};
</script>
