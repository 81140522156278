<template>
  <div class="top">
    <span class="number">Заказ {{ order.id }}</span>
    <span class="date">от {{ dateFormatString(order.created_at) }}</span>
    <span class="price" v-if="order.is_paid">
      {{ formatPrice(order.total) }} руб.
    </span>
    <a
      class="price"
      v-else-if="order.status.widgets.length > 0"
      @click.stop="generatorPaymentOrder"
    >
      Оплатить {{ formatPrice(order.total) }} руб.
    </a>
  </div>
  <div class="status">
    <span
      v-if="!order.is_paid && order.status.widgets.length > 0"
      class="not-paid"
    >
      Ожидает оплаты
    </span>
    <span v-else>{{ getDestinationValue(order) }}</span>
    <OrderStatusesBlock :order="order" />
  </div>
  <div class="bottom">
    <div class="delivery">
      <span v-if="order.delivery_type === 1" class="type">
        Пункт самовывоза {{ order.delivery_service_name }}
      </span>
      <span v-else-if="order.delivery_type === 3" class="type">
        Почтомат {{ order.delivery_service_name }}
      </span>
      <span v-else-if="order.delivery_type === 2" class="type">
        Доставка курьером {{ order.delivery_service_name }}
      </span>
      <span class="address">{{ order.delivery_address }}</span>
      <span
        v-if="order.delivery_type === 1 || order.delivery_type === 3"
        class="working"
      >
        Ежедневно 10:00-20:00
      </span>
    </div>
    <span class="price" v-if="order.is_paid">
      {{ formatPrice(order.total) }} руб.
    </span>
    <div class="products">
      <a
        v-for="product in order.products"
        :key="product"
        class="product-item"
        :style="{
          background: product.product.images[0].image.background_color,
        }"
      >
        <img :src="product.product.images[0].image.image_object_small" />
        <span v-if="product.quantity > 1">{{ product.quantity }}</span>
      </a>
    </div>
    <a
      class="price"
      v-if="order.status.widgets.length > 0"
      @click.stop="generatorPaymentOrder"
    >
      Оплатить {{ formatPrice(order.total) }} руб.
    </a>
  </div>
  <div class="virtual-products" v-if="order.virtual_products.length > 0">
    <span>Цифровые товары</span>
    <div class="products">
      <a
        v-for="product in order.virtual_products"
        :key="product"
        class="product-item"
        :style="{
          background: product.product.images[0].image.background_color,
        }"
      >
        <img :src="product.product.images[0].image.image_object_small" />
      </a>
    </div>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import OrderStatusesBlock from "@/components/OrderStatusesBlock.vue";
import api from "@/api";

export default {
  mixins: [pageMixin, productMixin],
  components: {
    OrderStatusesBlock,
  },
  props: {
    order: Object,
  },
  methods: {
    getDestinationValue(order) {
      if (order["status"]["destination"] === 1) {
        return "Заказ оформлен";
      } else if (order["status"]["destination"] === 2) {
        return "В работе";
      } else if (order["status"]["destination"] === 3) {
        return "В доставке";
      } else if (order["status"]["destination"] === 4) {
        return "Ожидают получения";
      } else if (order["status"]["destination"] === 5) {
        return "Доставлен";
      } else if (order["status"]["destination"] === 6) {
        return "Отменённые";
      } else if (order["status"]["destination"] === 7) {
        return "Возвраты";
      }
    },
    async generatorPaymentOrder() {
      try {
        const result = await api.shop.postShopGeneratorPaymentOrder(
          this.order.id
        );
        window.location = result["data"]["url"];
      } catch (error) {
        alert("Ошибка");
      }
    },
  },
};
</script>
